<template>
    <div class="custom-providers-container">
        <div class="custom-providers-list">
            <table class="table">
                <thead>
                    <tr>
                        <th class="activity-name">
                            {{ $t("vigik.custom.providerName") }}
                        </th>
                        <th class="check">
                            {{ $t("vigik.custom.status") }}
                        </th>
                        <th class="number">
                            {{ $t("vigik.schedule.schedule") }}
                        </th>
                        <th class="cancel"></th>
                    </tr>
                </thead>
                <tbody v-if="providers.length > 0">
                    <tr v-for="(provider, index) in providers" :key="index">
                        <td>
                            {{ provider.company }}
                        </td>
                        <td class="status">
                            <BasicSelect
                                class="select-status"
                                name="level"
                                :options="chooseOptions(provider)"
                                :model-value="provider.level"
                                :needDefault="false"
                                @change="
                                    changeLevel(
                                        $event.target.value,
                                        provider.id,
                                        index
                                    )
                                "
                            />
                        </td>
                        <td>
                            <div
                                v-if="
                                    provider.level === 'WHITELIST' ||
                                    provider.level === 'SELECTED'
                                "
                            >
                                <Tooltip
                                    :text="
                                        $t('vigik.custom.timetablesCustomised')
                                    "
                                    v-if="
                                        provider.schedule_id &&
                                        provider.level !== 'SELECTED'
                                    "
                                >
                                    <i
                                        class="fas fa-clock"
                                        @click="goToProviderSchedule(provider)"
                                        style="color: #ef7b1dff"
                                    ></i>
                                </Tooltip>
                                <Tooltip
                                    :text="$t('vigik.custom.timetablesDefault')"
                                    v-else
                                >
                                    <i
                                        class="fas fa-pen"
                                        @click="goToProviderSchedule(provider)"
                                        style="color: #53a6bb"
                                    ></i>
                                </Tooltip>
                            </div>
                        </td>
                        <td>
                            <BasicButton
                                color="blue"
                                v-if="provider.level !== provider.originalLevel"
                                @click="cancel(provider.id, index)"
                                >{{ $t("button.cancel") }}</BasicButton
                            >
                        </td>
                    </tr>
                </tbody>
                <tbody v-else-if="isSearchText" class="no-activities">
                    <tr>
                        <td colspan="4">
                            {{ $t("global.array.noSearchData") }}
                        </td>
                    </tr>
                </tbody>
                <tbody v-else class="no-activities">
                    <tr>
                        <td colspan="4">
                            {{ $t("global.array.emptyList") }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex"
import BasicSelect from "@/components/basic/BasicSelect.vue"
import BasicButton from "@/components/basic/BasicButton.vue"
import Tooltip from "@/components/basic/Tooltip.vue"

export default {
    name: "CustomProvidersView",
    components: { BasicSelect, BasicButton, Tooltip },
    props: {
        isSearchText: Boolean,
    },
    data: function () {
        return {
            blacklistOptions: [
                {
                    value: "BLACKLIST",
                    name: this.$t("vigik.global.blacklisted", {
                        count: 0,
                    }),
                },
                {
                    value: "SELECTED",
                    name: this.$t("vigik.global.default"),
                },
            ],
            defaultOptions: [
                {
                    value: "SELECTED",
                    name: this.$t("vigik.global.default"),
                },
                {
                    value: "BLACKLIST",
                    name: this.$t("vigik.global.blacklisted", {
                        count: 0,
                    }),
                },
            ],
            whitelistOptions: [
                {
                    value: "WHITELIST",
                    name: this.$t("vigik.global.whitelisted", {
                        count: 0,
                    }),
                },
                {
                    value: "BLACKLIST",
                    name: this.$t("vigik.global.blacklisted", {
                        count: 0,
                    }),
                },
            ],
        }
    },
    computed: {
        providers() {
            return this.$store.state["updateProvidersStatus"].providers
        },

        ...mapGetters({
            thereAreCustomProviders:
                "updateProvidersStatus/thereAreCustomProviders",
            providers: "updateProvidersStatus/visibleList",
        }),
    },
    methods: {
        ...mapActions({
            resetProviderLevel: "updateProvidersStatus/resetProviderLevel",
        }),
        goToProviderSchedule(provider) {
            this.$emit("goToProvidersSchedule", provider)
        },
        whitelistStatus(action, id) {
            if (action === "add") {
                this.$store.dispatch(
                    "updateProvidersStatus/addProviderToWhitelisted",
                    id
                )
            } else {
                this.$store.dispatch(
                    "updateProvidersStatus/removeProviderFromWhitelisted",
                    id
                )
            }
        },
        defaultStatus(action, id) {
            if (action === "add") {
                this.$store.dispatch(
                    "updateProvidersStatus/addProviderToDefault",
                    id
                )
            } else if (action === "remove") {
                this.$store.dispatch(
                    "updateProvidersStatus/removeProviderFromDefault",
                    id
                )
            }
        },
        changeLevel(newLevel, providerId) {
            this.$store.dispatch("updateProvidersStatus/updateProviderLevel", {
                providerId,
                level: newLevel,
            })
        },
        cancel(id) {
            this.resetProviderLevel(id)
        },
        chooseOptions(provider) {
            let options
            switch (provider.originalLevel) {
                case "WHITELIST":
                    options = this.whitelistOptions
                    break
                case "BLACKLIST":
                    options = this.blacklistOptions
                    break
                case "SELECTED":
                    options = this.defaultOptions
                    break
            }

            return options
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/details.scss";
.custom-providers-container {
    width: 100%;
    height: 98%;
    overflow-x: auto;

    @media all and (max-width: 768px) {
        padding-right: 20px;
    }

    .custom-providers-list {
        width: 100%;

        .table {
            width: 100%;
            border-spacing: 0px 1em;

            thead {
                border-bottom: 1px solid $grey-silver;

                th {
                    padding: 1em 0;
                    padding-right: 1em;
                    font-size: $normal;
                    color: $blue-neutral;
                    font-family: $font_avenir_heavy;
                    text-wrap: nowrap;
                }

                .activity-name {
                    width: 30%;
                }

                .check {
                    width: 20%;
                }

                .number {
                    width: max-content;
                }

                .cancel {
                    width: 100%;
                }
            }

            tbody {
                font-size: $small;

                &.no-activities {
                    tr {
                        border-bottom: 0px solid $grey-silver !important;
                        td {
                            color: $pink;
                            font-family: $font_avenir_heavy;
                            border-bottom: 0px solid $grey-silver !important;
                            text-align: center;
                        }
                    }
                }
                tr {
                    border-bottom: 1px solid $grey-silver;

                    td {
                        padding: 0.7em 0;
                        padding-right: 1em;
                        vertical-align: middle;
                        font-family: $font-avenir-roman;
                        color: $blue-neutral;
                        text-wrap: nowrap;

                        &.status {
                            .select-status {
                                width: 70%;
                                min-width: 120px;
                            }
                        }

                        i {
                            font-size: $normal;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
