<template>
    <div class="custom-access-type" v-if="!loading">
        <div class="top-container">
            <div class="return_page">
                <VigikBreadCrumb />
            </div>
            <div class="top-block">
                <div class="search-block">
                    <div class="title">
                        <div class="change-name">
                            <div>
                                <p>{{ accessTypeInfos.name }}</p>
                                <i class="fa fa-pencil" @click="editHeader"></i>
                                <div
                                    class="stroke"
                                    v-if="
                                        $route.params.isVigik === '1' &&
                                        gestionnaireId !== '0'
                                    "
                                />
                                <Tooltip :text="$t('global.modal.valid.reset')">
                                    <i
                                        class="fas fa-undo"
                                        @click="saveRAZ"
                                        v-if="
                                            gestionnaireId !== '0' &&
                                            ($route.params.isIntratone ===
                                                '1' ||
                                                $route.params.isVigik === '1')
                                        "
                                    ></i>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div>
                        {{
                            accessTypeInfos.description
                                ? accessTypeInfos.description
                                : ""
                        }}
                    </div>
                </div>
                <div class="searchAndChanges"></div>
            </div>
            <div class="tab-select-container">
                <div class="tab-select-wrapper">
                    <div
                        @click="updateSelectedTab('activities')"
                        :class="{ selectTab: selectTab === 'activities' }"
                    >
                        <p>{{ $t("vigik.global.activities") }}</p>
                    </div>
                    <div
                        @click="updateSelectedTab('whitelistedProviders')"
                        :class="{
                            selectTab: selectTab === 'whitelistedProviders',
                        }"
                    >
                        <p>{{ $t("vigik.global.customProviders") }}</p>
                    </div>
                    <div
                        @click="updateSelectedTab('blacklistedProviders')"
                        :class="{
                            selectTab: selectTab === 'blacklistedProviders',
                        }"
                    >
                        <p>{{ $t("vigik.global.bannedProviders") }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="selectTab === 'activities'" class="activities">
            <div
                class="search-and-confirmation search-and-confirmation-activity"
            >
                <div class="search-wrapper">
                    <BasicSearch
                        class="search"
                        :searchPlaceholder="$t('vigik.activity.search')"
                        :previousSearchText="previousSearchText"
                        @search="searchActivities"
                    />
                </div>
                <div class="confirmation-wrapper">
                    <div class="buttons-wrapper">
                        <basic-button
                            color="orange"
                            @click="saveVerif"
                            :disabled="add.length === 0 && del.length === 0"
                        >
                            <span class="text">
                                {{ $t("button.validate") }}
                            </span>
                        </basic-button>
                        <basic-button
                            color="blue"
                            @click="cancelVerif"
                            :disabled="add.length === 0 && del.length === 0"
                        >
                            <span class="text">
                                {{ $t("button.cancel") }}
                            </span>
                        </basic-button>
                    </div>
                </div>
            </div>
            <div class="array-wrapper">
                <CustomAccessType
                    v-if="!tabLoader"
                    class="array-wrapper"
                    :isSearchText="isSearchText"
                    :currentPage="currentPage"
                    :pagesCount="pagesCount"
                    :checkOne="checkOne"
                    :activities="activities"
                    :initialArray="initialArray"
                    :accessModePriority="accessModePriority"
                    :data="data"
                    :accessTypeName="accessTypeName"
                    :changeAccessTypeName="changeAccessTypeName"
                    :newAccessTypeName="newAccessTypeName"
                    :keyPressed="keyPressed"
                    :previousSearchText="previousSearchText"
                    :addNumber="add"
                    :delNumber="del"
                    @activityProvider="activityProvider($event)"
                    @activitySchedule="activitySchedule($event)"
                    @changedAllow="changedAllow"
                />
                <div v-else class="block-loader">
                    <Loader />
                </div>
            </div>
        </div>
        <div v-if="selectTab === 'whitelistedProviders'" class="activities">
            <span class="search-label">
                {{ $t("vigik.provider.customProviders") }}
            </span>
            <div class="search-and-confirmation">
                <div class="search-wrapper">
                    <BasicSearch
                        class="search"
                        :searchPlaceholder="
                            $t('vigik.provider.searchCustomProviders')
                        "
                        :previousSearchText="previousSearchText"
                        @search="getProvidersSearch"
                    />
                </div>
                <div class="confirmation-wrapper">
                    <div
                        class="number-modification"
                        v-if="modifiedProviders.length"
                        @click="seeChangements"
                    >
                        <i class="fa fa-exclamation-triangle"></i>
                        <p>
                            {{
                                $t("vigik.activity.modification", {
                                    count: modifiedProviders.length,
                                })
                            }}
                        </p>
                    </div>
                    <div class="buttons-wrapper">
                        <basic-button
                            color="orange"
                            @click="updateProvidersStatus"
                            :disabled="
                                modifiedProviders.length > 0 ? false : true
                            "
                        >
                            <span class="text">
                                {{ $t("button.validate") }}
                            </span>
                        </basic-button>
                        <basic-button
                            color="blue"
                            @click="resetAllProviderLevel"
                            :disabled="
                                modifiedProviders.length > 0 ? false : true
                            "
                        >
                            <span class="text">
                                {{ $t("button.cancel") }}
                            </span>
                        </basic-button>
                    </div>
                </div>
            </div>
            <div v-if="!tabLoader" class="array-wrapper">
                <CustomProvidersView
                    :isSearchText="isSearchText"
                    class="array-wrapper"
                    @goToProvidersSchedule="goToProvidersSchedule"
                />
            </div>
            <div v-else class="block-loader">
                <Loader />
            </div>
            <Pagination
                :pagesCount="pagesCount"
                :page="currentPage"
                @new-current-page="newCurrentPage"
            />
        </div>
        <div v-if="selectTab === 'blacklistedProviders'" class="activities">
            <span class="search-label">
                {{ $t("vigik.provider.forbiddenProviders") }}
            </span>
            <div class="search-and-confirmation">
                <div class="search-wrapper">
                    <BasicSearch
                        class="search-wrapper"
                        :searchPlaceholder="
                            $t('vigik.provider.searchForbiddenProviders')
                        "
                        :previousSearchText="previousSearchText"
                        @search="getProvidersSearch"
                    />
                </div>
                <div class="confirmation-wrapper">
                    <div
                        class="number-modification"
                        v-if="modifiedProviders.length"
                        @click="seeChangements"
                    >
                        <i class="fa fa-exclamation-triangle"></i>
                        <p>
                            {{
                                $t("vigik.activity.modification", {
                                    count: modifiedProviders.length,
                                })
                            }}
                        </p>
                    </div>
                    <div class="buttons-wrapper">
                        <basic-button
                            color="orange"
                            @click="updateProvidersStatus"
                            :disabled="
                                modifiedProviders.length > 0 ? false : true
                            "
                        >
                            <span class="text">
                                {{ $t("button.validate") }}
                            </span>
                        </basic-button>
                        <basic-button
                            color="blue"
                            @click="resetAllProviderLevel"
                            :disabled="
                                modifiedProviders.length > 0 ? false : true
                            "
                        >
                            <span class="text">
                                {{ $t("button.cancel") }}
                            </span>
                        </basic-button>
                    </div>
                </div>
            </div>
            <div v-if="!tabLoader" class="array-wrapper">
                <ForbiddenProvidersView
                    :isSearchText="isSearchText"
                    @goToProvidersSchedule="goToProvidersSchedule"
                    class="array-wrapper"
                />
                <Pagination
                    v-if="pagesCount > 1"
                    :pagesCount="pagesCount"
                    :page="currentPage"
                    @new-current-page="newCurrentPage"
                />
            </div>
            <div v-else class="block-loader">
                <Loader />
            </div>
        </div>
    </div>
    <div v-if="loading" class="block-loader">
        <Loader />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import {
    addActivitiesToAccessType,
    deleteAccessType,
    getAccessTypeActivities,
    getAccessTypeInfos,
    getProvidersWhitelisted,
    getProvidersBlacklisted,
    getAccessTypeProviders,
    updateProvidersLevel,
} from "@/services/intratone/accesstype"
import { useToast } from "vue-toastification"

import CustomAccessType from "@/views/vigik/activity/CustomAccessType.vue"
import CustomProvidersView from "./CustomProvidersView.vue"
import ForbiddenProvidersView from "./ForbiddenProvidersView.vue"
import VigikBreadCrumb from "@/components/entities/VigikBreadCrumb.vue"
import Pagination from "@/components/basic/Pagination.vue"

import BasicButton from "@/components/basic/BasicButton.vue"
import BasicSearch from "@/components/basic/BasicSearch.vue"
import Loader from "@/components/basic/Loader"
import Tooltip from "@/components/basic/Tooltip.vue"

const toast = useToast()

export default {
    name: "ActivityHomeView",
    components: {
        CustomAccessType,
        CustomProvidersView,
        ForbiddenProvidersView,
        BasicButton,
        BasicSearch,
        VigikBreadCrumb,
        Pagination,
        Tooltip,
        Loader,
    },
    data() {
        return {
            currentPage: 1,
            pagesCount: 0,
            checkOne: false,
            activities: [],
            initialArray: [],
            add: [],
            del: [],
            changedData: [],
            accessModePriority: null,
            data: false,
            accessTypeInfos: {},
            newAccessTypeName: null,
            keyPressed: false,
            loading: false,
            previousSearchText: null,
            pendingSelectTab: null,
            selectTab: "activities",
            tabLoader: true,
            whitelistedProvidersSearch: "",
            blacklistedProvidersSearch: "",
            isSearchText: false,
            gestionnaireId: null,
        }
    },
    async mounted() {
        if (!this.$route.params.searchTerm) {
            this.loading = true
            await this.getAccessTypeInfos()
            await this.getData()
            this.identifyOrSelectTab()
            this.loading = false
        } else {
            this.previousSearchText = this.$route.params.searchTerm
            this.searchActivities(this.previousSearchText)
            this.identifyOrSelectTab()
        }
    },
    computed: {
        ...mapGetters({
            modifiedProviders: "updateProvidersStatus/modifiedList",
            providers: "updateProvidersStatus/visibleList",
        }),
    },
    methods: {
        ...mapActions({
            resetAllProviderLevel:
                "updateProvidersStatus/resetAllProviderLevel",
        }),
        /**
         * Update selected tab value
         * @param {string} tab values can be oneOf ['activities', 'whitelistedProviders', 'blacklistedProviders']
         */
        async updateSelectedTab(tab) {
            // if tab has not changed do nothing
            if (tab === this.selectTab) return
            // if there are providers or activities modified
            if (
                this.thereAreModifiedProviders() ||
                this.thereAreModifiedActivities()
            ) {
                if (this.selectTab === "activities") {
                    return this.openModal("validation", {
                        title: "valid.edit",
                        valid: async () => {
                            await this.saveActivities()
                            await this.redirectCorrespondingTab(tab)
                        },
                        refuse: async () => {
                            await this.cancelVerif()
                            await this.redirectCorrespondingTab(tab)
                        },
                    })
                } else {
                    // force user to valid or discard modifications
                    return this.openModal("validation", {
                        title: "valid.edit",
                        valid: async () => {
                            await this.updateProvidersStatus()
                            await this.redirectCorrespondingTab(tab)
                        },
                        refuse: async () => {
                            await this.resetAllProviderLevel()
                            await this.redirectCorrespondingTab(tab)
                        },
                    })
                }
            }
            await this.redirectCorrespondingTab(tab)
        },
        /**
         * Update selectTab value and redirect to it
         * @param tab
         * @returns {Promise<void>}
         */
        async redirectCorrespondingTab(tab) {
            this.selectTab = tab
            if (tab === "activities") {
                this.identifyOrSelectTab("0")
                await this.getData()
            } else if (tab === "whitelistedProviders") {
                this.identifyOrSelectTab("1")
                await this.goToWhitelistedProvidersTab(true, true)
            } else {
                this.identifyOrSelectTab("2")
                await this.getBlacklistedProviders(true, true)
            }
        },
        identifyOrSelectTab(tab) {
            if (tab) {
                this.$router.replace({
                    name: "CustomAccessType",
                    params: {
                        selectedTab: tab,
                    },
                })
            } else {
                switch (this.$route.params.selectedTab) {
                    case "0":
                        this.selectTab = "activities"
                        break
                    case "1":
                        this.selectTab = "whitelistedProviders"
                        this.getWhitelistedProviders(true, true)
                        break
                    case "2":
                        this.selectTab = "blacklistedProviders"
                        this.getBlacklistedProviders(true, true)
                        break
                    default:
                        this.selectTab = "activities"
                        break
                }
            }
        },
        thereAreModifiedProviders() {
            return this.modifiedProviders.length > 0
        },
        thereAreModifiedActivities() {
            return this.add.length > 0 || this.del.length > 0
        },
        goToProvidersSchedule(provider) {
            if (this.modifiedProviders.length > 0) {
                this.openModal("validation", {
                    title: this.$t("providersStatus.title"),
                    question: this.$t("global.modal.valid.edit"),
                    valid: async () => {
                        await this.updateProvidersStatus()
                        this.goToSchedule(provider)
                    },
                })
            } else {
                this.goToSchedule(provider)
            }
        },
        goToSchedule(provider) {
            this.$router.push({
                name: "ProviderSchedule",
                params: {
                    accessId: this.$route.params.id,
                    providerId: provider.id,
                    providerName: provider.company,
                    // activityId: this.$route.params.activityId,
                    // activityName: this.$route.params.activityName,
                    isPersonnalised:
                        provider.level === "SELECTED"
                            ? "0"
                            : provider.level === "WHITELIST"
                            ? "1"
                            : provider.level === "BLACKLIST"
                            ? "2"
                            : null,
                    scheduleId: provider.schedule_id ?? 0,
                },
            })
        },
        getIcon(activity) {
            switch (activity.icon) {
                case "ICON_ASSISTANCE":
                    return "fa-user-nurse"
                case "ICON_EMERGENCY":
                    return "fa-ambulance"
                case "ICON_MAIL":
                    return "fa-envelope"
                case "ICON_PACKAGE":
                    return "fa-box"
                case "ICON_WATER":
                    return "fa-water"
                case "ICON_FLYER":
                    return "fa-newspaper"
                case "ICON_TRASH":
                    return "fa-trash"
                case "ICON_GAS":
                    return "fa-cloud"
                case "ICON_ELECTRICITY":
                    return "fa-bolt"
                case "ICON_TELECOM":
                    return "fa-broadcast-tower"
                case "ICON_VIGIK3":
                    return "fa-door-closed"
                case "ICON_PRESS":
                    return "fa-book-open"
                case "ICON_CLEANING":
                    return "fa-broom"
                case "ICON_GARDEN":
                    return "fa-tree"
                case "ICON_SAFETY":
                    return "fa-lock"
                default:
                    return "fa-icons"
            }
        },
        seeChangements() {
            this.openModal("providers-status", {
                title: "providersStatus.title",
                count: this.modifiedProviders.length,

                deleteChangement: (infos) => {
                    this.deleteChangement(infos)
                },
            })
        },
        async getAccessTypeInfos() {
            this.isSearchText = false
            await getAccessTypeInfos(this.$route.params.id).then((response) => {
                this.accessTypeInfos = response.data
            })
        },
        async getData() {
            this.activities = []
            this.add = []
            this.del = []
            this.tabLoader = true
            this.isSearchText = false
            await getAccessTypeActivities(this.$route.params.id, {
                page: this.currentPage,
            }).then((response) => {
                this.accessModePriority = response.data.access_mode_priority
                this.initialArray = []
                response.data.list.forEach((e) => {
                    this.activities.push({
                        id: e.id,
                        blacklists: e.blacklists,
                        code: e.code,
                        label: e.label,
                        schedule_id: e.schedule_id,
                        schedule_original_id: e.schedule_original_id,
                        allow: !!e.allow,
                        initialAllow: !!e.allow,
                        icon: e.icon,
                        whitelists: e.whitelists,
                    })
                    this.initialArray.push({
                        id: e.id,
                        allow: !!e.allow,
                    })
                })
                this.gestionnaireId = response.data.gestionnaire_id
                this.data = true
                this.tabLoader = false
            })
        },
        changedAllow() {
            this.activities.forEach((e) => {
                e.initialAllow = e.allow
            })

            return this.checkAddAndDel()
        },
        async searchActivities(value) {
            this.tabLoader = true
            this.isSearchText = true
            this.activities = []

            await getAccessTypeActivities(this.$route.params.id, {
                page: this.currentPage,
                q: value,
            }).then((response) => {
                response.data.list.forEach((e) => {
                    this.activities.push({
                        id: e.id,
                        blacklists: e.blacklists,
                        code: e.code,
                        label: e.label,
                        schedule_id: e.schedule_id,
                        schedule_original_id: e.schedule_original_id,
                        allow: !!e.allow,
                        initialAllow: !!e.allow,
                        icon: e.icon,
                        whitelists: e.whitelists,
                    })
                    this.initialArray.push({
                        id: e.id,
                        allow: !!e.allow,
                    })
                })
            })

            this.tabLoader = false
        },
        async goToVigik() {
            this.$router.push({
                name: "Vigik3",
            })
        },
        async activitySchedule(activity) {
            const del = this.checkAddAndDel().del

            const even = (element) => element === activity.id
            if (!del.some(even) && activity.allow) {
                this.$router.push({
                    name: "ActivitySchedule",
                    params: {
                        accessId: this.$route.params.id,
                        activityId: activity.id,
                        activityAllow: activity.allow ? "1" : "0",
                        activityName: activity.label,
                        scheduleId: activity.schedule_id ?? 0,
                        isPersonnalised:
                            activity.schedule_id === null ? "0" : "1",
                    },
                })
            }
        },
        activityProvider(activity) {
            this.$router.push({
                name: "ActivityProviders",
                params: {
                    accessId: this.$route.params.id,
                    activityStatus: activity.allow ? "1" : "0",
                    activityId: activity.id,
                    activityName: activity.label,
                },
            })
        },
        async cancelVerif() {
            this.add = []
            this.del = []

            this.loading = true
            await this.getData()
            this.loading = false
        },
        async saveVerif() {
            this.loading = true
            await this.saveActivities()
            this.loading = false
        },
        saveRAZ() {
            this.openModal("validation", {
                title: "valid.reset",
                question: this.$t("vigik.access.reset"),
                valid: async () => {
                    await this.deleteAccessType()
                },
            })
        },
        checkAddAndDel() {
            const startAdd = []
            const startDel = []

            this.activities.forEach((activity, index) => {
                this.initialArray.forEach((e, i) => {
                    if (
                        e.id === activity.id &&
                        e.allow === false &&
                        activity.allow === true
                    ) {
                        startAdd.push(activity.id)
                    } else if (
                        e.id === activity.id &&
                        e.allow === true &&
                        activity.allow === false
                    ) {
                        startDel.push(activity.id)
                    }
                })
            })
            //delete same value in add and del
            const unique = (value, index, self) => {
                return self.indexOf(value) === index
            }

            const del = startDel.filter(unique)
            const add = startAdd.filter(unique)

            this.add = add
            this.del = del

            return {
                add: add,
                del: del,
            }
        },
        async deleteAccessType() {
            await deleteAccessType(this.$route.params.id)
            await this.getData()
        },
        async saveActivities() {
            this.loading = true
            const add = this.checkAddAndDel().add
            const del = this.checkAddAndDel().del
            await addActivitiesToAccessType(this.$route.params.id, {
                add,
                del,
            }).then((response) => {
                toast.success(
                    this.$t("vigik.access.update-access-check", {
                        name: this.$route.params.vigikName,
                    }),
                    {
                        icon: "fas fa-check",
                    }
                )
                this.add = []
                this.del = []
            })

            await this.getData()
            this.loading = false
        },

        /**
         * Get while list provider from IntratoneAPI
         * @param {boolean} resetAllProviders, use to reset provider list, otherwise update provider list with new providers
         * @param tabAsChange, use to know if tab has been changed
         * @returns {Promise<void>}
         */
        async getWhitelistedProviders(resetAllProviders, tabAsChange) {
            /**
             * If tab as change and there are modified providers
             *  request user to valid or discard modifications
             */
            if (tabAsChange === true && this.modifiedProviders.length > 0) {
                // force user to valid or discard modifications
                this.openModal("validation", {
                    title: this.$t("providersStatus.title"),
                    question: this.$t("global.modal.valid.edit"),
                    valid: async () => {
                        await this.updateProvidersStatus()
                        await this.goToWhitelistedProvidersTab(
                            tabAsChange,
                            resetAllProviders
                        )
                    },
                    refuse: async () => {
                        await this.resetAllProviderLevel()
                        await this.goToWhitelistedProvidersTab(
                            tabAsChange,
                            resetAllProviders
                        )
                    },
                })
            } else {
                await this.goToWhitelistedProvidersTab(
                    tabAsChange,
                    resetAllProviders
                )
            }
        },

        async goToWhitelistedProvidersTab(tabAsChange, resetAllProviders) {
            this.tabLoader = true
            this.isSearchText = false

            if (tabAsChange) this.whitelistedProvidersSearch = ""

            if (
                this.whitelistedProvidersSearch &&
                this.whitelistedProvidersSearch !== ""
            ) {
                await this.getProvidersSearch(
                    this.whitelistedProvidersSearch,
                    true
                )
            } else {
                await getProvidersWhitelisted(this.$route.params.id, {
                    page: this.currentPage,
                    q: "",
                    limit: 1000,
                }).then((response) => {
                    resetAllProviders === true
                        ? this.$store.dispatch(
                              "updateProvidersStatus/setProviders",
                              response.data.list
                          )
                        : this.$store.dispatch(
                              "updateProvidersStatus/updateProviders",
                              response.data.list
                          )

                    this.tabLoader = false
                })
            }
        },

        async getBlacklistedProviders(resetAllProviders, tabAsChange) {
            this.tabLoader = true
            this.isSearchText = false

            if (tabAsChange) this.blacklistedProvidersSearch = ""

            if (
                this.blacklistedProvidersSearch &&
                this.blacklistedProvidersSearch !== ""
            ) {
                await this.getProvidersSearch(
                    this.blacklistedProvidersSearch,
                    true
                )
            } else {
                await getProvidersBlacklisted(this.$route.params.id, {
                    page: 1,
                    q: "",
                    limit: 1000,
                }).then((response) => {
                    resetAllProviders === true
                        ? this.$store.dispatch(
                              "updateProvidersStatus/setProviders",
                              response.data.list
                          )
                        : this.$store.dispatch(
                              "updateProvidersStatus/updateProviders",
                              response.data.list
                          )

                    this.tabLoader = false
                })
            }
        },

        async getProvidersSearch(value, resetAllProviders) {
            this.whitelistedProvidersSearch = value
            this.blacklistedProvidersSearch = value
            this.tabLoader = true
            if (!value && this.selectTab === "blacklistedProviders") {
                await this.getBlacklistedProviders()
            } else if (!value && this.selectTab === "whitelistedProviders") {
                await this.getWhitelistedProviders()
            } else {
                this.isSearchText = true
                this.getAccessTypeProvidersWithoutDebounce(
                    value,
                    resetAllProviders
                )
            }
        },

        async getAccessTypeProvidersWithoutDebounce(value, resetAllProviders) {
            await getAccessTypeProviders(this.$route.params.id, {
                page: this.currentPage,
                q: value,
                limit: 1000,
            })
                .then((response) => {
                    resetAllProviders === true
                        ? this.$store.dispatch(
                              "updateProvidersStatus/setProviders",
                              response.data.list
                          )
                        : this.$store.dispatch(
                              "updateProvidersStatus/updateProviders",
                              response.data.list
                          )
                })
                .finally(() => {
                    this.tabLoader = false
                })
        },

        async updateProvidersStatus() {
            this.tabLoader = true
            let blacklist = []
            let whitelist = []
            let reset = []

            //Récupérer providers modifiés via getters des prestataires modifiés
            const providers =
                this.$store.getters["updateProvidersStatus/modifiedList"]

            //Boucler pour récupérer le level (pas le originalLevel)
            for (let i = 0; i < providers.length; i++) {
                //assigner à blacklist, whitelist ou reset en fonction du level
                switch (providers[i].level) {
                    case "BLACKLIST":
                        blacklist.push(providers[i].id)
                        break
                    case "WHITELIST":
                        whitelist.push(providers[i].id)
                        break
                    case "SELECTED":
                        reset.push(providers[i].id)
                        break
                    default:
                        break
                }
            }

            //Procéder à la requête
            await updateProvidersLevel(this.$route.params.id, {
                blacklist: blacklist,
                whitelist: whitelist,
                reset: reset,
            }).then(async (response) => {
                if (this.selectTab === "blacklistedProviders") {
                    await this.getBlacklistedProviders(true)
                } else if (this.selectTab === "whitelistedProviders") {
                    await this.getWhitelistedProviders(true)
                }

                this.tabLoader = false
            })
        },

        editHeader() {
            this.$router.push({
                name: "VigikUpdateAccessTypeInfos",
                params: {
                    id: this.$route.params.id,
                },
            })
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.selectTab === "activities") {
            if (this.thereAreModifiedActivities()) {
                this.openModal("validation", {
                    title: "valid.edit",
                    valid: async () => {
                        this.loading = true
                        await this.saveActivities()
                        this.loading = false
                        next()
                    },
                    refuse: () => {
                        next()
                    },
                })
            } else {
                next()
            }
        } else {
            /**
             * If there are modified providers
             *  request user to valid or discard modifications
             */
            if (this.thereAreModifiedProviders()) {
                // force user to valid or discard modifications
                this.openModal("validation", {
                    title: this.$t("valid.edit"),
                    valid: async () => {
                        await this.updateProvidersStatus()
                        next()
                    },
                    refuse: async () => {
                        await this.resetAllProviderLevel()
                        next()
                    },
                })
            } else {
                next()
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/details.scss";

@media all and (max-width: 768px) {
    .mobile {
        display: none;
    }
}

.custom-access-type {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media all and (min-width: 768px) {
        padding-left: 80px;
        padding-right: 80px;
    }

    .top-container {
        padding-top: 1em;
        gap: 1em;
        display: flex;
        flex-direction: column;
        justify-content: start;

        top: 0;
        z-index: 1;
        background: linear-gradient(
            180deg,
            rgb(255, 255, 255) 90%,
            rgba(255, 255, 255, 0)
        );

        @media all and (min-width: 768px) {
            justify-content: space-between;
        }

        @media all and (max-width: 768px) {
            flex-direction: column;
            padding: 20px 0px 20px 20px;
        }
        .return_page {
            @media all and (max-width: 768px) {
                margin: 5px 0;
            }
            a {
                color: $blue-darker;
                cursor: pointer;
                text-decoration: none;
                transition: color 200ms;
                font-family: "Avenir Heavy";
                font-size: 12px;
                i {
                    margin-right: 10px;
                    transition: margin 200ms;
                }
            }
            a:hover {
                color: $orange-neutral;
                i {
                    margin-right: 15px;
                }
            }
        }
        .top-block {
            display: flex;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: flex-start;
            width: 100%;
            gap: 0.5em;

            @media screen and (max-width: 768px) {
                flex-direction: column;
                align-items: center;
            }
            .search-block {
                display: flex;
                flex-direction: column;
                gap: 1em;
                width: 100%;

                @media screen and (max-width: 768px) {
                    align-items: flex-start;
                    padding-right: 20px;
                }

                .title {
                    font-family: $font_avenir_black;
                    transition: all 200ms;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    gap: 0.5em;
                    @media all and (min-width: 768px) {
                        font-size: $veryBig;
                        gap: 0.2em;

                        h1 {
                            font-size: $veryBig;
                            margin: 0;
                        }
                    }
                    @media all and (max-width: 768px) {
                        font-size: $veryBig;
                        gap: 0.2em;
                        line-height: 20px;
                    }
                    @media screen and (max-width: 1550px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    .change-name {
                        width: 100%;
                        div {
                            display: flex;
                            transition: all 200ms;
                            align-items: center;
                            color: $blue-neutral;
                            border: none;
                            gap: 0.2em;

                            p {
                                margin: 0;
                                line-height: 1em;
                            }

                            i {
                                height: 100%;
                                font-size: $big;
                                border-radius: 20px;
                                padding: 0.3em;
                                color: $blue-neutral;
                                background-color: $white;
                                transition: all 0.2s;

                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }

                        .stroke {
                            height: 20px;
                            border-left: 2px solid $grey-neutral;
                        }
                    }
                }

                .priority {
                    @media screen and (max-width: 768px) {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0.1em;
                    }
                }
            }
            .priority {
                padding-top: 0.5em;
                display: flex;
                gap: 1em;
                align-items: center;

                p {
                    margin: 0;
                }
            }
        }

        .search-block {
            display: flex;
            width: 100%;

            @media all and (min-width: 768px) {
                justify-content: space-between;
            }

            @media all and (max-width: 768px) {
                gap: 0.5em;
                flex-direction: column;
                align-items: center;
            }
        }

        .tab-select-container {
            width: 100%;
            overflow: auto;
            top: 0;
            z-index: 1;
            border-bottom: 2px solid $pink-lighter;

            .tab-select-wrapper {
                display: flex;
                background-color: rgba($pink-lighter, 0.4);
                width: max-content;
                border-radius: 10px 10px 0 0;

                div {
                    padding: 1em 1.2em;
                    border-radius: 10px 10px 0 0;
                    transition: all 0.2s;
                    &:hover {
                        cursor: pointer;
                        background-color: rgba($pink-lighter, 1);
                        transition: all 0.2s;
                    }

                    p {
                        margin: 0;
                        font-family: $font_avenir_heavy;
                        font-size: $normal;
                    }
                }

                .selectTab {
                    background-color: $pink-lighter;
                }
            }
        }
    }

    .activities {
        display: flex;
        flex-direction: column;
        overflow: auto;

        @media all and (max-width: 768px) {
            padding: 0 0 0 20px;
        }
        .search-label {
            padding-top: 2em;
            font-size: $normal;
            font-family: $font_avenir_heavy;

            @media all and (max-width: 768px) {
                padding-top: 0em;
            }
        }

        .search-and-confirmation {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0em 0 2em 0;
            flex-grow: 1;
            gap: 1em;

            @media all and (max-width: 768px) {
                padding: 0em 20px 2em 0;
                flex-direction: column;
                align-items: start;
            }

            &.search-and-confirmation-activity {
                padding-top: 2em;
                @media all and (max-width: 768px) {
                    padding-top: 0em;
                }
            }

            .search-wrapper {
                display: flex;
                flex-direction: column;
                gap: 1em;
                width: 100%;
                min-width: 400px;
                max-width: 500px;
                @media all and (max-width: 768px) {
                    width: 100%;
                    min-width: 0px;
                    max-width: 500px;
                }

                .search {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;

                    @media all and (max-width: 768px) {
                        width: 50vw;
                        min-width: 300px;
                        max-width: 450px;

                        @media all and (max-width: 768px) {
                            width: 100%;
                            min-width: 0px;
                            max-width: 450px;
                        }
                    }
                }
            }

            .confirmation-wrapper {
                display: flex;
                gap: 1em;
                @media all and (max-width: 768px) {
                    width: 100%;
                    flex-direction: column;
                    align-items: flex-start;
                }
                span {
                    font-family: $font-avenir-medium;
                }

                .number-modification {
                    display: flex;
                    align-items: center;
                    gap: 0.5em;

                    &:hover {
                        cursor: pointer;
                    }

                    i {
                        color: $yellow-warning;
                    }
                    p {
                        font-family: $font_avenir_heavy;
                        margin: 0;
                        text-decoration: underline;
                    }
                }

                .buttons-wrapper {
                    display: flex;
                    gap: 1em;
                }
            }
        }
        .array-wrapper {
            display: flex;
            height: 100%;
            overflow: auto;
        }

        .loader-tabs {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }
}
.block-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
